import { Component, OnInit, NgZone } from '@angular/core';
import {
  Router,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
//import { Socket } from 'ngx-socket-io';
import { ServiceService } from '../../../core/services/service.service';
import { io } from 'socket.io-client';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { ToastrService } from 'ngx-toastr';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { WebsocketService } from 'src/app/core/services/websocket.service';
import {
  Swlist,
  WatchStatusList,
} from 'src/app/core/models/watch/watch_status.model';
import { GlobalConstants } from 'src/app/common/global-constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { checkDeviceLogoutModule } from 'src/app/core/models/admin/admin.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  idOperatorAdmin: any;
  adminRole: any;
  countSosAll: number = 0;
  countSosStart: number = 0;
  countSosWorking: number = 0;
  operator: boolean = true;
  admin: boolean = false;
  private socket: any;
  dataRole: any;
  typeAdmin: any;
  typeKeyword: any;
  typeName: any;
  dataJobOperatorAll: any = [];

  selectedValue: string = '';
  roleAdmins: any[] = [];
  dataOperatorAllToRole: any = [];
  dataWatchAll: Swlist[] = [];

  event$;

  selectAdminRoleDropdown: boolean = false;

  timedOut = false;
  lastPing?: Date;

  operatorsOfAdmin: string[] = [];

  constructor(
    private toastr: ToastrService,
    private sharedServiceService: SharedServiceService,
    // private socket_: Socket,
    public dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private ServiceService: ServiceService,
    private webSocketService: WebsocketService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    this.idOperatorAdmin = localStorage.getItem('currentIdAdmin');
    this.adminRole = localStorage.getItem('currentRole');
    if (this.adminRole == 'Operator') {
      this.idleLoad();
    }
    // this.socket = io('http://192.168.1.43:2022/', { transports: ['websocket', 'polling', 'flashsocket'] });


    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        event.url == '/admin-list'
          ? (this.selectAdminRoleDropdown = true)
          : (this.selectAdminRoleDropdown = false);

          if (event.url.startsWith('/caseOP') || event.url.startsWith('/caseProfileNew')) {
            // this.showHead= false;
          }
          else{
            this.socket = io(GlobalConstants.socket, {
              autoConnect: true,
              reconnection: true, // Enable reconnection
              reconnectionAttempts: Infinity, // Limit the number of reconnection attempts
              reconnectionDelay: 1000,
              transports: ['websocket', 'polling', 'flashsocket']
            });
          }
      }
    });
  }

  ngOnDestroy() {
    this.event$.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.ServiceService.GetAdminRole_().then((resRole) => {
      // console.log(resRole);
      this.dataRole = resRole;
    });
    this.dataOperator();
    this.dataWatch();
    var resultRole: any;
    await this.ServiceService.GetAdminRole_().then((resRole) => {
      resultRole = resRole;
      // console.log(resultRole);

      this.dataRole = resultRole;
      for (let i = 0; i < resultRole.length; i++) {
        if (resultRole[i].typeIsActive == true) {
          this.roleAdmins.push({
            value: resultRole[i].typeAdmin,
            viewValue: resultRole[i].typeName,
          });
        }
      }
    });
    if (localStorage.getItem('showAdmin') != null)
    this.selectedValue = localStorage.getItem('showAdmin')!.toString();

    await this.getOperatorsOfAdmin();
    this.getAlertSosJob(this.idOperatorAdmin, this.adminRole);

    this.socket.on('datamessage', async (data: any) => {
      console.log("header ------ data SOS ",data);
      let typeKeyword: string = this.dataRole.find(
        (item: any) => item.typeAdmin == localStorage.getItem('showAdmin')
      ).typeKeyword;
      if (data.OperatorMonitorType == typeKeyword) {
        var index = this.dataJobOperatorAll.findIndex(
          (x: { ojobId: number }) => Number(x.ojobId) === Number(data.OJob_ID)
        );
        // console.log("index ",index);

        if (index == -1 && data.OJob_Status == 'start') {
          this.countSosStart++;
          this.countSosAll++;
        }
      }
    });

    this.socket.on('dataMessageworkJob', (data: any) => {
      console.log("dataMessageworkJob",data);
      this.sharedServiceService.ongoingSOSCase(data);
      if (data.data.portal == localStorage.getItem('showAdmin')) {
        var index1 = this.dataJobOperatorAll.findIndex(
          (x: { ojobId: number }) => Number(x.ojobId) === Number(data.data.id)
        );
        if (index1 != -1) {
          this.dataJobOperatorAll[index1].ojobStatus = data.data.status;
          var index2 = this.dataJobOperatorAll.findIndex(
            (x: { ojobId: number }) => Number(x.ojobId) === Number(data.data.id)
          );
          if (this.dataJobOperatorAll[index2].ojobStatus != 'start') {
            if (this.dataJobOperatorAll[index2].ojobStatus == 'work') {
              this.countSosStart--;
              this.countSosWorking++;
            }
            if (this.dataJobOperatorAll[index2].ojobStatus == 'end') {
              this.countSosWorking--;
              this.countSosAll--;
            }
          }
        }
      }
    });

    this.socket.on(
      'alert_smartWatchDisconnect',
      (datasmartWatchDisconnect: any) => {
        console.log("datasmartWatchDisconnect ", datasmartWatchDisconnect);
        let typeKeyword: string = this.dataRole.find(
          (item: any) => item.typeAdmin == localStorage.getItem('showAdmin')
        ).typeKeyword;
        if (datasmartWatchDisconnect.OperatorMonitorType == typeKeyword) {
          // for (let i = 0; i < this.dataWatchAll.length; i++) {
          var index = this.dataWatchAll.findIndex(
            (x) => x.sws_imei == datasmartWatchDisconnect.swcn_imei
          );
          if (index > -1) {
          if (datasmartWatchDisconnect.swcn_onlineStatus == 'Online') {
            if (this.dataWatchAll[index].typeAdmin == this.selectedValue) {
              this.showWatchOnlineAndOffline(
                this.dataWatchAll[index].deviceName,
                true
              );
              this.dataWatchAll[index].sws_wearTimeUpdate =
                datasmartWatchDisconnect.swcn_datetime;
              var dataJsonUserAndDevice = {
                dataDevices: this.dataWatchAll,
              };
              this.sharedServiceService.updateDataUserAndDevice(
                dataJsonUserAndDevice
              );
              // break;
            }
          } else {
            if (this.dataWatchAll[index].typeAdmin == this.selectedValue) {
              this.dataWatchAll[index].sws_wearTimeUpdate =
                datasmartWatchDisconnect.swcn_datetime;
              var dataJsonUserAndDevice = {
                dataDevices: this.dataWatchAll,
              };
              this.sharedServiceService.updateDataUserAndDevice(
                dataJsonUserAndDevice
              );
              this.showWatchOnlineAndOffline(
                this.dataWatchAll[index].deviceName,
                false
              );
              // break;
            }
          }

          }
          // }
        }
      }
    );

    // if(this.adminRole != "Operator"){
    this.socket.on(
      'dataOperatorOnlineAndOffline',
      (dataOperatorOnlineAndOffline: any) => {
        console.log("dataOperatorOnlineAndOffline ", dataOperatorOnlineAndOffline);
        if (
          this.operatorsOfAdmin.length &&
          this.operatorsOfAdmin.find(
            (item: any) => item == dataOperatorOnlineAndOffline.operatorID
          )
        ) {
          for (let i = 0; i < this.dataOperatorAllToRole.length; i++) {
            if (
              this.dataOperatorAllToRole[i].adminId ==
              dataOperatorOnlineAndOffline.operatorID
            ) {
              if (dataOperatorOnlineAndOffline.status == true) {
                this.dataOperatorAllToRole[i].operatorOnlineStatus = true;
                // console.log("online", this.dataOperatorAllToRole);
                this.sharedServiceService.updateOperatorOnline(
                  this.dataOperatorAllToRole
                );
                var nameOpertor = `${this.dataOperatorAllToRole[i].operatorFirstname}  ${this.dataOperatorAllToRole[i].operatorLastname}`;
                this.showOperatorOnlineAndOffline(nameOpertor, true);
                break;
              } else {
                this.dataOperatorAllToRole[i].operatorOnlineStatus = false;
                // console.log("offline", this.dataOperatorAllToRole);
                this.sharedServiceService.updateOperatorOnline(
                  this.dataOperatorAllToRole
                );
                var nameOpertor = `${this.dataOperatorAllToRole[i].operatorFirstname}  ${this.dataOperatorAllToRole[i].operatorLastname}`;
                this.showOperatorOnlineAndOffline(nameOpertor, false);
                break;
              }
            }
          }
        }
      }
    );
    // }

    if (this.adminRole == 'Filter') {
      this.socket.on('TickerSocketChanel', async (alert: any) => {
        // console.log("filter alert -> ", alert);
        if (
          alert.TkVisible &&
          alert.TypeAdmin == localStorage.getItem('showAdmin')
        ) {
          this.webSocketService.playAudio(alert.DeviceName);
          let showText: string = `Device: ${alert.DeviceName}`;
          let showHead: string = '';
          switch (alert.sos_type) {
            case 'bloodpressure-detect':
              showHead = 'BP Alert';
              break;
            case 'bloodpressure-lostdata':
              showHead = 'BP Alert';
              break;
            case 'bodytemp-detect':
              showText += '<br>Temp : ' + alert.temperature_val;
              showHead = 'LowTemp Alert';
              break;
            case 'bodytemp-lostdata':
              showText += '<br>Temp : ' + alert.temperature_val;
              showHead = 'LowTemp Alert';
              break;
            case 'spo2-detect':
              showHead = 'SpO2 Alert';
              break;
            case 'spo2-lostdata':
              showHead = 'SpO2 Alert';
              break;
            case 'heartrate-detect':
              showText += '<br>HeartRate : ' + alert.heartrate_val;
              showHead = 'HeartRate Alert';
              break;
            case 'heartrate-lostdata':
              showText += '<br>HeartRate : ' + alert.heartrate_val;
              showHead = 'HeartRate Alert';
              break;
            default:
              break;
          }
          this.toastr.error(`${showText}`, showHead, {
            enableHtml: true,
            newestOnTop: true,
            timeOut: 12000,
            extendedTimeOut: 3000,
            progressBar: true,
          });
        }
      });
    }
  }

  async getOperatorsOfAdmin() {
    let opType: string = this.roleAdmins.find((item: any) => item.value == this.selectedValue).viewValue;
    await this.ServiceService.getOperatorIdList_(this.idOperatorAdmin, opType).then(
      (res) => {
        if (res.length) this.operatorsOfAdmin = res;
      },
      (err) => {
        console.log('err ->', err);
      }
    );
  }

  dataOperator() {
    this.sharedServiceService.dataOperatorOnline.subscribe((message) => {
      // console.log("countOpertorOnline message ID--------- ", message);
      if (message != '') {
        this.dataOperatorAllToRole = message;
      }
    });
  }

  dataWatch() {
    this.sharedServiceService.dataDeviceAndUseralue.subscribe((message) => {
      if (message != '') {
        // console.log("dataDeviceAndUseralue -------- ", message);
        this.dataWatchAll = message.dataDevices;
      }
    });
  }

  logout() {
    this.dialog.open(DialogLoginOut);
  }

  async getAlertSosJob(idOperaTorAdmin: string, role: string) {
    this.countSosAll = 0;
    this.countSosStart = 0;
    this.countSosWorking = 0;
    if (role == 'Operator') {
      this.operator = true;
      this.admin = false;
      this.ServiceService.GetJobByOperators_(idOperaTorAdmin).then(
        (dataJob) => {
          this.dataJobOperatorAll = dataJob;
          // console.log(" this.dataJobOperatorAll ", this.dataJobOperatorAll);

          for (let i = 0; i < this.dataJobOperatorAll.length; i++) {
            if (this.dataJobOperatorAll[i].ojobStatus == 'start') {
              this.countSosStart++;
              this.countSosAll++;
            } else if (this.dataJobOperatorAll[i].ojobStatus == 'work') {
              this.countSosWorking++;
              this.countSosAll++;
            }
          }
        }
      );
    } else {
      this.admin = true;
      this.operator = false;
      var dataSOS: any;
      if (role != null) {
        // console.log("aaa ",role);
        if (role != 'SuperAdmin') {
          dataSOS = await this.dataRole.find(
            (d: { typeAdmin: any }) => d.typeAdmin == role
          );
          // console.log(">> ",  dataSOS);
          this.typeAdmin = dataSOS.typeAdmin;
          this.typeKeyword = dataSOS.typeKeyword;
          this.typeName = dataSOS.typeName;

          this.ServiceService.GetJobOperatorsAll_(role, this.typeKeyword).then(
            (resJobOperatorAll) => {
              this.dataJobOperatorAll = resJobOperatorAll;
              for (let i = 0; i < this.dataJobOperatorAll.length; i++) {
                if (this.dataJobOperatorAll[i].ojobStatus == 'start') {
                  this.countSosStart++;
                  this.countSosAll++;
                } else if (this.dataJobOperatorAll[i].ojobStatus == 'work') {
                  this.countSosWorking++;
                  this.countSosAll++;
                }
              }
            }
          );
        } else {
          dataSOS = await this.dataRole.find(
            (d: { typeAdmin: any }) => d.typeAdmin == this.selectedValue
          );
          // console.log(">> ",  dataSOS);
          this.typeAdmin = dataSOS.typeAdmin;
          this.typeKeyword = dataSOS.typeKeyword;
          this.typeName = dataSOS.typeName;

          this.ServiceService.GetJobOperatorsAll_(role, this.typeKeyword).then(
            (dataJobOperatorAll) => {
              this.dataJobOperatorAll = dataJobOperatorAll;
              // console.log("resJob : ", resJobOperatorAll);
              for (let i = 0; i < this.dataJobOperatorAll.length; i++) {
                if (this.dataJobOperatorAll[i].ojobStatus == 'start') {
                  this.countSosStart++;
                  this.countSosAll++;
                } else if (this.dataJobOperatorAll[i].ojobStatus == 'work') {
                  this.countSosWorking++;
                  this.countSosAll++;
                }
              }
            }
          );
        }
      }
    }
  }

  onChangeAdminRole(role: string) {
    // localStorage.getItem("currentUser");
    localStorage.setItem('showAdmin', role);
    location.reload();
  }

  showOperatorOnlineAndOffline(name: string, status: any) {
    for (let i = 0; i < this.dataRole.length; i++) {
      if (this.dataRole[i].typeAdmin == this.selectedValue) {
        if (this.adminRole != 'Operator') {
          this.playAudio();
          if (status == true) {
            this.toastr.success(`Name : ${name}`, 'Operator Online', {
              enableHtml: true,
              newestOnTop: true,
              timeOut: 12000,
              extendedTimeOut: 3000,
              progressBar: true,
            });
          } else {
            this.toastr.info(`Name : ${name}`, 'Operator Offline', {
              enableHtml: true,
              newestOnTop: true,
              timeOut: 12000,
              extendedTimeOut: 3000,
              progressBar: true,
            });
          }
        }
      }
    }
  }

  showWatchOnlineAndOffline(name: string, status: any) {
    if (this.adminRole != 'Operator') {
      if (status == true) {
        this.watchOnlinePlayAudio();
        this.toastr.success(`Name : ${name}`, 'SmartWatch Online', {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 12000,
          extendedTimeOut: 3000,
          progressBar: true,
        });
      } else {
        this.watchOfflinePlayAudio();
        this.toastr.warning(`Name : ${name}`, 'SmartWatch Offline', {
          enableHtml: true,
          newestOnTop: true,
          timeOut: 12000,
          extendedTimeOut: 3000,
          progressBar: true,
        });
      }
    }
  }

  playAudio() {
    let audio = new Audio();
    audio.src =
      '../../../../assets/adminlte/dist/sound/8KR4YVH-notification-2.mp3';
    audio.load();
    audio.play();
  }

  watchOnlinePlayAudio() {
    let audio = new Audio();
    audio.src = '../../../../assets/adminlte/dist/sound/watch-online.wav';
    audio.load();
    audio.play();
  }

  watchOfflinePlayAudio() {
    let audio = new Audio();
    audio.src = '../../../../assets/adminlte/dist/sound/watch-offline.wav';
    audio.load();
    audio.play();
  }

  idleLoad() {
    this.idle.setIdle(43200);
    this.idle.setTimeout(3);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      var resUpdateStatusOperator: any;
      if (this.adminRole == 'Operator') {
        this.ServiceService.UpdateStatusOperator_(
          this.idOperatorAdmin,
          false
        ).then((dataUpdateStatusOperator) => {
          resUpdateStatusOperator = dataUpdateStatusOperator;
          localStorage.clear();
          localStorage.setItem("PTLOG","idleClear"+ new Date());
          window.location.reload();
        });
      } else {
        localStorage.clear();
        localStorage.setItem("PTLOG_NoOP","idleClear"+ new Date());
        window.location.reload();
      }
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.resetIdle();
  }

  resetIdle() {
    this.idle.watch();
    this.timedOut = false;
  }
}

@Component({
  selector: 'modal-logout',
  templateUrl: './modal-logout.html',
})
export class DialogLoginOut {
  idAdmin: any;
  roleAdmin: any;
  deviceInfo:any;
  isDesktopDevice: boolean = false;
  isTablet: boolean = false;
  isMobile: boolean = false;
  constructor(private ServiceService: ServiceService,private deviceService: DeviceDetectorService,) {
    this.idAdmin = localStorage.getItem('currentIdAdmin');
    this.roleAdmin = localStorage.getItem('currentRole');
    this.epicFunction();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    // console.log("deviceInfo : ",this.deviceInfo);
    // console.log("isMobile : ",this.isMobile);
    // console.log("isTablet : ",this.isTablet);
    // console.log("isDesktopDevice : ",this.isDesktopDevice);
  }
  logoutapp() {

    var resUpdateStatusOperator: any;
    // this.router.navigate(['/dashboard'], { replaceUrl: true }).then(() => {window.location.reload();});
    if (this.roleAdmin == 'Operator') {
      this.ServiceService.UpdateStatusOperator_(this.idAdmin, false).then(
        (dataUpdateStatusOperator) => {
          resUpdateStatusOperator = dataUpdateStatusOperator;
          localStorage.removeItem('currentUser');
          localStorage.removeItem('currentIdAdmin');
          localStorage.removeItem('currentRole');
          localStorage.removeItem('showAdmin');
          localStorage.removeItem('nameOpertor');
          window.location.reload();
        }
      );
    } else {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentIdAdmin');
      localStorage.removeItem('currentRole');
      localStorage.removeItem('showAdmin');
      localStorage.removeItem('nameOpertor');
      window.location.reload();
    }

    var logoutUser : checkDeviceLogoutModule =  {
      AdminUsername:localStorage.getItem("currentUsername")!.toString(),
      AdminDeviceType:this.isDesktopDevice == true ? "computer":"mobile"
    };
    // console.log(logoutUser);

    this.ServiceService.CheckDeviceLogoutModule_(logoutUser).then((resData) =>{
      // console.log(resData);
    });
  }
}
